<template>
  <div class="class-detail"><!--v-loading="pageLoading"-->
    <el-card class="box-card publishDetail" shadow="never">
      <div class="course-header publist-detail-header" :style="{ 'background-image': 'url('+defaultHeaderBg+')', 'background-repeat': 'no-repeat' , 'background-size': '100% 6.25vw' }">
        <div class="header-left">
          <div class="img-div">
            <img :src="sendRecordInfo.coverImg ? sendRecordInfo.coverImg : defaultHeaderImg" alt="" class="course-img" />
          </div>
          <div class="course-info">
            <div class="title">{{ sendRecordInfo.publishTitle }}</div>
            <div class="course-info-bottom">
              <!-- <div class="bottom-item">
                <div class="label long">课程标题：</div>
                <div class="text long">{{ sendRecordInfo.courseName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label long">课程内容：</div>
                <div class="text long">{{ sendRecordInfo.courseThemeName }}</div>
                <div class="line"></div>
              </div> -->
              <div class="bottom-item">
                <div class="label long">推送人：</div>
                <div class="text long">{{ sendRecordInfo.sendPerson }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item" v-if="sendRecordInfo.submitTypeName">
                <div class="label long">提交方式：</div>
                <div class="text long">{{ sendRecordInfo.submitTypeName }}</div>
                <div class="line" v-if="sendRecordInfo.updateTime || sendRecordInfo.submitEndTime"></div>
              </div>
              <div class="bottom-item" v-if="sendRecordInfo.updateTime">
                <div class="label long">推送时间：</div>
                <div class="text long">{{ sendRecordInfo.updateTime }}</div>
                <div class="line" v-if="sendRecordInfo.submitEndTime && sendRecordInfo.submitType !== ''"></div>
              </div>
              <div class="bottom-item" v-if="sendRecordInfo.submitEndTime && sendRecordInfo.submitType !== ''">
                <div class="label long">截至时间：</div>
                <div class="text long">{{ sendRecordInfo.submitEndTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
          <!-- <el-tooltip class="item" effect="light" popper-class="customPopper" content="预览" placement="bottom"> -->
            <img :src="detailRecordViewIcon" alt="" @click="viewRecord" style="width: 3.38542vw;height: 1.61458vw;" />
          <!-- </el-tooltip> -->
        </div>
      </div>
    </el-card>
    <div class="send-record-class-info" v-if="classList.length > 0">
      <div class="class-info-outside">
        <div class="class-info-list">
          <div class="class-item" :class="{ active: item.classesLabel == chooseClassId }" v-for="item in classList" :key="item.classesLabel" @click="handleChooseClass(item.classesLabel)">{{ item.classesName + "(" + item.classesCount + ")"}}</div>
        </div>
      </div>
      <div class="progress-info-outside">
        <div class="progress-info">
          <div class="progress-item">
            <el-progress type="circle" :width="105" :percentage="viewPercent" :stroke-width="10" :color="'#3AA1FF'"></el-progress>
            <div class="item-text" style="width: calc(100% - 5.46875vw);text-align: right;">
              <span>查看</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ viewNum }}</span>
            </div>
          </div>
          <div class="progress-item" v-if="sendRecordInfo.submitType">
            <el-progress type="circle" :width="105" :percentage="submitPercent" :stroke-width="10" :color="'#4ECB73'"></el-progress>
            <div class="item-text" style="width: calc(100% - 5.46875vw);text-align: right;">
              <span>提交</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ submitNum }}</span>
            </div>
          </div>
          <div class="progress-item" v-if="sendRecordInfo.submitType">
            <el-progress type="circle" :width="105" :percentage="noSubmitPercent" :stroke-width="10" :color="'#FBD437'"></el-progress>
            <div class="item-text" style="width: calc(100% - 5.46875vw);text-align: right;">
              <span>未提交</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ noSubmitNum }}</span>
            </div>
          </div>
          <div class="progress-item">
            <div class="circle link"><img :src="linkIcon" alt=""></div>
            <div class="item-text" style="width: calc(100% - 5.26042vw);text-align: right;">
              <span>点赞</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ linkNum }}</span>
            </div>
          </div>
          <div class="progress-item">
            <div class="circle reply"><img :src="replyIcon" alt=""></div>
            <div class="item-text" style="width: calc(100% - 5.26042vw);text-align: right;">
              <span>回复</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ replyNum }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="send-record-list">
      <div class="send-recond-title">全部评论</div>
      <div class="send-record-item" v-for="(item,i) in commentList" :key="item.id">
        <div class="record-item-header">
          <img class="header-left" :src="item.headImg ? item.headImg : defaultAvatarImg" alt="">
          <div class="header-right">
            <div class="item-title">{{ item.parentName }}</div>
            <div class="item-content" :ref="'item-content-'+item.id" v-html="item.content" :style="{ height: !item.commentShowHideFlag ? 'auto' : item.isShowHide ? 'auto' : '3.33333vw', overflow: !item.commentShowHideFlag ? 'auto' : item.isShowHide ? 'auto' : 'hidden' }"></div>
            <div class="item-img" v-if="item.fileList" :ref="'item-img-'+item.id" :style="{ height: !item.commentShowHideFlag ? 'auto' : item.isShowHide ? 'auto' : '6.30208vw', overflow: !item.commentShowHideFlag ? 'auto' : item.isShowHide ? 'auto' : 'hidden' }">
              <div class="file-item" v-for="fileInfo in item.fileList" :key="fileInfo.id" @click="(e) => handleImgClick(e, item.fileList)">
                <img v-if="fileInfo.fileType == 'img'" :src="fileInfo.fileUrl" alt="">
                <video controls v-if="fileInfo.fileType == 'video'" :src="fileInfo.fileUrl" preload="load"></video>
                <audio controls v-if="fileInfo.fileType == 'audio'" :src="fileInfo.fileUrl"></audio>
              </div>
            </div>
            <div class="show-or-hide" @click="handleCommentShowHide(item,i)" v-show="item.commentShowHideFlag">
              <span>{{ item.isShowHide ? '收起' : '展开' }}</span>
              <img :class="{ noShow: !item.isShowHide }" :src="showTurnIcon" alt="">
            </div>
            <div class="time-buttons">
              <div class="time">{{ item.createTime }}</div>
              <div class="buttons">
                <div class="button-item" @click="handleCommentReplyLink(item,i,null)">
                  <img :src="item.isLink ? recordLinkActiveIcon : recordLinkIcon" alt="">
                  <span>{{ item.likeCount }}</span>
                </div>
                <div class="button-item">
                  <img :src="recordReplyIcon" @click="handleCommentReply(item,i,'comment')" alt="">
                  <span>{{ item.replyCount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reply-record" v-if="item.children && item.children.length > 0">
          <div class="reply-record-item" v-for="(info,j) in item.childrenShowList" :key="info.id">
            <img class="reply-img" :src="item.headImg ? item.headImg : recordReplyDefaultImg" alt="">
            <div class="item-info">
              <div class="item-title">
                <div class="name">
                  <span class="text">{{ info.firstName }}</span>
                  <span v-if="info.secondName" style="margin-right: 1.25vw;font-size: 0.98958vw;">回复</span>
                  <span v-if="info.secondName" class="text">{{ info.secondName }}</span>
                  <span class="time">{{ info.createTime }}</span>
                </div>
                <div class="buttons">
                  <div class="button-item" @click="handleCommentReplyLink(info,j,i)">
                    <img :src="info.isLink ? recordLinkActiveIcon : recordLinkIcon" alt="">
                    <span>{{ info.likeCount }}</span>
                  </div>
                  <div class="button-item">
                    <img :src="recordReplyIcon" @click="handleCommentReply(info,i,'reply',item)" alt="">
                    <span>{{ info.replyCount }}</span>
                  </div>
                </div>
              </div>
              <div class="item-content" v-html="info.content"></div>
            </div>
          </div>
          <div class="show-or-hide" @click="handleReplyShowHide(item,i)" v-show="item.showHideFlag">
            <span>{{ item.isReplyShowHide ? '收起全部回复' : '加载更多' }}</span>
            <img v-show="item.isReplyShowHide" :src="showTurnIcon" alt="">
          </div>
        </div>
        <div class="reply-input" v-show="item.replyDivShowHide">
          <div class="reply-input-outside">
            <div class="reply-person" :ref="'reply-person-'+item.id">{{ item.replyPersonText ? '@'+item.replyPersonText : "" }}</div>
            <div class="reply-input-textarea" :style="{ width: item.replyPersonText ? (item.InputWidth !== '' ? 'calc(100% - 0.83333vw - '+item.InputWidth+')' : 'auto') : '100%' }">
              <el-input type="textarea" :ref="'reply-input-'+item.id" :autosize="{ minRows: 1, maxRows: 4 }" v-model="item.replyText" @blur="(e) => handleReplyDivBlur(e,item,i)" @keyup.native="(e) => handleDelComment(e,item,i)" resize="none" :maxlength="1000"></el-input>
            </div>
          </div>
          <el-tooltip class="item" effect="light" popper-class="customPopper" content="发布" placement="top">
            <img class="send-reply-button" :src="recordReplySubmitImg" @click="handleCommentSubmit(item)" alt="">
          </el-tooltip>
        </div>
      </div>
      <div v-if="pageFlag && page.total > 0" class="l-pages">
        <div class="total-num">
          显示第{{ showNum }}条记录，共{{ page.total }}条记录
        </div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.num"
          @size-change="handleSizeChange"
          :page-size="page.size"
          :page-sizes="page.pageSizes"
          layout="prev, pager, next, sizes"
          :total="page.total"
        ></el-pagination>
      </div>
    </div>
    <!-- <el-dialog title="编辑班级" :visible.sync="classDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
      <el-form class="dialog-form" :model="classDialogForm" ref="classDialogForm" :rules="rules" label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="选择组织：" prop="organization" >
              <el-select class="form-input" v-model="classDialogForm.organization" clearable placeholder="请选择组织">
                <el-option v-for="item in organizationOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="选择学校：" prop="schoolName">
              <el-select class="form-input" v-model="classDialogForm.schoolName" clearable placeholder="请选择学校">
                <el-option v-for="item in schoolOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="选择年级：" prop="gradeName">
              <el-select class="form-input" v-model="classDialogForm.gradeName" clearable placeholder="请选择年级">
                <el-option v-for="item in gradeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="班级名称：" prop="className">
              <el-input class="form-input" v-model="classDialogForm.className" placeholder="请输入班级名称，支持汉字、数字、字母组合" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="班级编号：" prop="classNum">
              <el-input class="form-input" v-model="classDialogForm.classNum" placeholder="请输入班级编号" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="班主任：">
              <el-select class="form-input" v-model="classDialogForm.classTeacher" clearable placeholder="请选择班主任">
                <el-option v-for="item in classTeacherOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="排序：" prop="sort">
              <el-input class="form-input" v-model="classDialogForm.sort" placeholder="请输入排序" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="毕业班：" prop="isFinalClass">
              <el-switch v-model="classDialogForm.isFinalClass"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="状态：" prop="status">
              <el-switch v-model="classDialogForm.status"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="备注：">
              <el-input class="form-input" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请填写描述内容..." v-model="classDialogForm.description" resize="none" :maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit">保 存</el-button>
      </div>
    </el-dialog> -->
    <el-image  style="width: 0; height: 0;" ref="viewImg" :src="viewUrl" :preview-src-list="viewUrlList"></el-image>
    <publish-view :courseViewDialogVisible.sync="courseViewDialogVisible" :contentId="courseContentCatalogId"></publish-view>
  </div>
</template>
<script>
import '../../../assets/theme/fonts/DIN/DIN-Medium.css';
import { mapGetters, mapState } from 'vuex';
import PublishView from '@/components/publishView/index.vue';
import detailRecordViewIcon from '@/assets/img/detail-record-view-icon-text.png';
import defaultHeaderImg from '@/assets/img/default-header-img.png';
import defaultHeaderBg from '@/assets/img/detail-header-bg.png';
import linkIcon from '@/assets/img/link-icon.png';
import replyIcon from '@/assets/img/reply-icon.png';
import recordLinkIcon from '@/assets/img/record-link-icon.png';
import recordLinkActiveIcon from '@/assets/img/record-link-active-icon.png';
import recordReplyIcon from '@/assets/img/record-reply-icon.png';
import defaultAvatarImg from '@/assets/img/default-avatar-img.png';
import recordReplyDefaultImg from '@/assets/img/record-reply-default-img.png';
import recordReplySubmitImg from '@/assets/img/record-reply-submit-icon.png';
import showTurnIcon from '@/assets/img/first-panel-turn-icon.png';
import { getAgeScopeName, getCourseLevelName, getCourseTypeName, getCourseFieldName, getSubmitTypeName } from '@/utils/getDicName';
export default {
  name: 'sendRecordDetail',
  components: {
    PublishView
  },
  inject: ['tagClose'],
  data() {
    return {
      pageLoading: null, // 页面loading
      sendRecordInfo: {
        coverImg: '',
        publishTitle: '',
        courseName: '',
        courseThemeName: '',
        sendPerson: '',
        submitType: '',
        updateTime: '',
        submitEndTime: '',
      }, // 记录info

      recordId: null, // 推送记录id
      classList: [], // 班级list
      chooseClassId: '', // 选择班级id

      viewPercent: 0, // 查看百分比值
      viewNum: 0, // 查看数量
      submitPercent: 0, // 提交百分比值
      submitNum: 0, // 提交数量
      noSubmitPercent: 0, // 未提交百分比值
      noSubmitNum: 0, // 未提交数量
      linkNum: 0, // 点赞数量
      replyNum: 0, // 回复数量

      detailRecordViewIcon, // 预览icon
      defaultHeaderImg, // 头部信息默认图片
      defaultHeaderBg, // 头部背景图
      linkIcon, // 班级信息-点赞icon
      replyIcon, // 班级信息-回复icon
      recordLinkIcon, // 评论回复-点赞icon
      recordLinkActiveIcon, // 评论回复-点赞选中icon
      recordReplyIcon, // 评论回复-回复icon
      defaultAvatarImg, // 评论默认头像img
      recordReplyDefaultImg, // 回复默认头像img
      recordReplySubmitImg, // 提交按钮icon
      showTurnIcon, // 显示隐藏icon

      replyInfo: {}, // 被回复的信息

      commentList: [], // 评论列表
      page: {
        num: 1,
        size: 10,
        total: 0,
        pageSizes: [10, 20, 50, 100, 500],
      }, // 评论分页
      pageFlag: false, // 是否显示分页
      imgList: ['png', 'jpg', 'jpeg', 'gif'],

      courseViewDialogVisible: false, // 预览
      courseContentCatalogId: null, // 预览目录内容id

      viewUrl: '', //附件图片预览
      viewUrlList: [],//附件图片预览list
    };
  },
  computed: {
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      activityTypeOptions: (state) => state.common.activityTypeList,
      submitTypeOptions: (state) => state.common.submitTypeList
    }),
    showNum() {
      let start = (this.page.num - 1) * this.page.size + 1;
      let end = this.page.num * this.page.size > this.page.total ? this.page.total : this.page.num * this.page.size;
      return this.page.total === 0 ? this.page.total : `${ start }-${ end }`;
    },
  },
  created() {
    this.recordId = Number(this.$route.query.id);
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.getPushInfo();
    this.getPublishClassList();
  },
  methods: {
    getAgeScopeName,
    getCourseLevelName,
    getCourseTypeName,
    getCourseFieldName,
    getSubmitTypeName,
    // 获取推送记录信息
    getPushInfo() {
      this.$api.getPublishInfo(this.recordId).then(res => {
        if (res.data.code === 0) {
          this.sendRecordInfo.coverImg = res.data.data.coverImg;
          this.sendRecordInfo.publishTitle = res.data.data.publishTitle;
          this.sendRecordInfo.courseName = res.data.data.courseName;
          this.sendRecordInfo.courseThemeName = res.data.data.courseThemeName;
          this.sendRecordInfo.sendPerson = res.data.data.sendPerson;
          this.sendRecordInfo.submitType = res.data.data.submitType;
          this.sendRecordInfo.updateTime = res.data.data.status === '0' ? '' : res.data.data.updateTime;
          this.sendRecordInfo.submitEndTime = res.data.data.submitEndTime ? res.data.data.submitEndTime : '永久';

          let submitTypeList = res.data.data.submitType.split(',');
          let submitTypeName = [];
          submitTypeList.map((item) => {
            let name = this.getSubmitTypeName(item);
            if (name) {
              submitTypeName.push(name);
            }
          });

          this.sendRecordInfo.submitTypeName = submitTypeName.join(',');
          this.courseContentCatalogId = res.data.data.id;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取班级推送信息list
    getPublishClassList() {
      this.$api.getPublishClassList({ coursePublishInfoId: this.recordId }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data && res.data.data.length > 0) {
            this.classList = res.data.data;
            this.chooseClassId = res.data.data[0].classesLabel;
            this.$nextTick(() => {
              this.getPushAllCount();
            });
          } else {
            this.classList = [];
            this.pageLoading.close()
          }
        } else {
          this.$message.error(res.data.msg);
          this.pageLoading.close()
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    // 获取班级的查看、提交、未提交、点赞、回复数量
    getPushAllCount() {
      this.$api.getPublishClassAllCountList({ coursePublishInfoId: this.recordId, classesLabel: this.chooseClassId }).then(res => {
        if (res.data.code === 0) {
          let info = res.data.data;
          this.viewPercent = info.lookPer ? Number(info.lookPer) * 100 : 0; // 查看百分比值
          this.viewNum = info.lookCount; // 查看数量
          this.submitPercent = info.submitPer && this.sendRecordInfo.submitType != "" ? Number(info.submitPer) * 100 : 0; // 提交百分比值
          this.submitNum = this.sendRecordInfo.submitType != "" ? info.submitCount : 0; // 提交数量
          this.noSubmitPercent = info.noSubmitPer && this.sendRecordInfo.submitType != "" ? Number(info.noSubmitPer) * 100 : 0; // 未提交百分比值
          this.noSubmitNum = this.sendRecordInfo.submitType != "" ? info.noSubmitCount : 0; // 未提交数量
          this.linkNum = this.sendRecordInfo.submitType != "" ? info.likeCount : 0; // 点赞数量
          this.replyNum = this.sendRecordInfo.submitType != "" ? info.replyCount : 0;// 回复数量
          this.getPushClassCommentList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取班级的评论信息
    getPushClassCommentList() {
      this.$api.getPublishClassCommentList({ coursePublishInfoId: this.recordId, classesLabel: this.chooseClassId, current: this.page.num, size: this.page.size }).then(res => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          let infoList = res.data.data.list;
          if (infoList.length > 0) {
            infoList.map((item, j) => {
              item.parentName = item.createType == '0' ? item.parentName + '老师' : item.parentName;
              item.isShowHide = false;
              item.commentShowHideFlag = false;
              item.isReplyShowHide = false;
              item.replyDivShowHide = false
              item.replyText = '';
              item.replyPersonText = '';
              item.delCount = 0;
              item.InputWidth = ""
              if (item.children && item.children.length > 0) {
                item.childrenShowList = [];
                item.children.map((info, i) => {
                  let parentNameList = info.parentName.split('@');
                  if (info.createType == '0') {
                    info.firstName = parentNameList[0] + '老师';
                  } else {
                    info.firstName = parentNameList[0];
                  }
                  if (parentNameList.length == 2) {
                    info.secondName = parentNameList[1];
                  } else {
                    info.secondName = '';
                  }
                  if (item.children.length > 5) {
                    if (i < 5) {
                      item.childrenShowList.push(info);
                    }
                  } else {
                    item.childrenShowList.push(info);
                  }
                });
                if (item.children.length > 5) {
                  item.showHideFlag = true;
                  item.replyPageNum = 1;
                } else {
                  item.showHideFlag = false;
                }
              }
            });
          }
          this.commentList = infoList;
          this.page.total = res.data.data.total;
          if (res.data.data.total > 0) {
            this.pageFlag = true;
          } else {
            this.pageFlag = false;
          }
          this.$nextTick(() => {
            this.handleItemInfo();
          });
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    // 获取评论列表其他信息
    handleItemInfo() {
      this.commentList.map((item, j) => {
        if (item.children && item.children.length > 0) {
          item.children.map((info, i) => {
            // this.getCommentCountList(info.id,(data) => {
            //   let itemReplyInfo = JSON.parse(JSON.stringify(info))
            //   if(!data){
            //     itemReplyInfo.likeCount = 0
            //     itemReplyInfo.replyCount = 0
            //   } else {
            //     itemReplyInfo.likeCount = data.likeCount
            //     itemReplyInfo.replyCount = data.replyCount
            //   }

            // })
            this.getPublishClassCommentIsLink(info.id, (data) => {
              let itemReplyInfo = JSON.parse(JSON.stringify(info));
              if (!data) {
                itemReplyInfo.isLink = false;
              } else {
                itemReplyInfo.isLink = true;
              }
              this.$set(this.commentList[j].children, i, itemReplyInfo);
              if (i < this.commentList[j].childrenShowList.length) {
                this.$set(this.commentList[j].childrenShowList, i, itemReplyInfo);
              }
            });
          });
        }
      });
      setTimeout(() => {
        this.commentList.map((item, j) => {
          this.getCommentFileList(item.id, (data) => {
            let itemInfo = JSON.parse(JSON.stringify(item));
            if (!data) {
              itemInfo.fileList = null;
            } else {
              data.map((fileInfo) => {
                let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
                if (this.imgList.indexOf(type) != -1) {
                  fileInfo.fileType = 'img';
                } else if (type == 'mp4') {
                  fileInfo.fileType = 'video';
                } else if (type == 'mp3') {
                  fileInfo.fileType = 'audio';
                }
              });
              itemInfo.fileList = data;
            }
            // this.getCommentCountList(item.id,(data) => {
            //   if(!data){
            //     itemInfo.likeCount = 0
            //     itemInfo.replyCount = 0
            //   } else {
            //     itemInfo.likeCount = data.likeCount
            //     itemInfo.replyCount = data.replyCount
            //   }

            // })
            this.getPublishClassCommentIsLink(item.id, (data) => {
              if (!data) {
                itemInfo.isLink = false;
              } else {
                itemInfo.isLink = true;
              }
              this.$set(this.commentList, j, itemInfo);
            });
          });
        });
        setTimeout(() => {
          this.commentList.map((item, j) => {
            if (this.$refs['item-content-' + item.id][0].scrollHeight > 64) {
              item.commentShowHideFlag = true;
            } else {
              item.commentShowHideFlag = false;
            }
          });
        }, 300);
      }, 400);
    },
    // 评论/回复点赞处理
    handleCommentReplyLink(info, index, firstIndex) {
      let url = '';
      let type = '';
      let params = {
        commentoId: info.id
      };
      if (info.isLink) {
        url = '/api/techcommentlike/del';
        type = 'del';
      } else {
        url = '/api/techcommentlike';
        type = 'add';
        params.likeByType = '0';
      }
      this.$api.commentLinkHandle(url, params, type).then(res => {
        if (res.data.code === 0) {
          // let itemInfo = JSON.parse(JSON.stringify(info))
          // itemInfo.isLink = !itemInfo.isLink
          // if(type == 'del'){
          //   itemInfo.likeCount--
          // } else if(type == 'add'){
          //   itemInfo.likeCount++
          // }
          // if(firstIndex !== null){
          //   this.$set(this.commentList[firstIndex].children,index,itemInfo)
          //   this.$set(this.commentList[firstIndex].childrenShowList,index,itemInfo)
          // } else {
          //   this.$set(this.commentList,index,itemInfo)
          // }
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
          this.getPushAllCount();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 评论/回复回复处理
    handleCommentReply(info, index, type, commentInfo) {
      console.log(info);
      this.replyInfo = JSON.parse(JSON.stringify(info));
      if (type == 'comment') {
        let resultInfo = JSON.parse(JSON.stringify(info));
        resultInfo.replyPersonText = info.parentName ? info.parentName.split('@')[0] : '';
        resultInfo.replyDivShowHide = true
        resultInfo.InputWidth = ""
        this.$set(this.commentList, index, resultInfo);
        setTimeout(() => {
          resultInfo.InputWidth = this.$refs['reply-person-' + info.id][0].offsetWidth+'px'
          this.$set(this.commentList, index, resultInfo);
          console.log(resultInfo.InputWidth)
          this.$nextTick(() => {
            this.$refs['reply-input-' + info.id][0].focus();
          })
        },300)
      } else if (type == 'reply') {
        let resultInfo = JSON.parse(JSON.stringify(commentInfo));
        resultInfo.replyPersonText = info.parentName ? info.parentName.split('@')[0] : '';
        resultInfo.replyDivShowHide = true
        resultInfo.InputWidth = ""
        this.$set(this.commentList, index, resultInfo);
        setTimeout(() => {
          // this.$refs['reply-input-' + commentInfo.id][0].$el.style.width = 'calc(100% - '+this.$refs['reply-person-' + commentInfo.id][0].offsetWidth+'px);'
          resultInfo.InputWidth = this.$refs['reply-person-' + commentInfo.id][0].offsetWidth+'px'
          console.log(resultInfo)
          this.$set(this.commentList, index, resultInfo);
          this.$nextTick(() => {
            console.log(this.commentList)
            this.$refs['reply-input-' + commentInfo.id][0].focus();
          })
        },300)
      }
    },
    // 回复框输入事件
    handleDelComment(e, info, index) {
      let resultInfo = JSON.parse(JSON.stringify(info));
      if (e.keyCode === 8 && resultInfo.replyText == '') {
        if (resultInfo.delCount === 1) {
          resultInfo.replyPersonText = '';
          this.replyInfo = {};
          resultInfo.delCount = 0;
          this.$set(this.commentList, index, resultInfo);
          this.$nextTick(() => {
            // this.$refs['reply-input-' + replyInfo.id][0].$el.style.width = '100%'
            resultInfo.width = '100%'
            this.$set(this.commentList, index, resultInfo);
          })
        } else {
          resultInfo.delCount = 1;
          this.$set(this.commentList, index, resultInfo);
        }
      } else {
        if (resultInfo.replyText !== '' && resultInfo.delCount === 1) {
          resultInfo.delCount = 0;
          this.$set(this.commentList, index, resultInfo);
        }
      }
    },
    //回复框失焦事件
    handleReplyDivBlur(e, info, index){
      let resultInfo = JSON.parse(JSON.stringify(info));
      if(resultInfo.replyText == ''){
        resultInfo.replyPersonText = '';
        resultInfo.delCount = 0;
        resultInfo.width = '100%'
        resultInfo.replyDivShowHide = false
        this.$set(this.commentList, index, resultInfo);
      }
    },
    // 回复提交
    handleCommentSubmit(info) {
      if (!info.replyText) {
        this.$message.warning('请先填写回复信息');
        return;
      }
      let params = {
        pushInfoId: this.recordId,
        createType: '0',
        content: info.replyText,
        flag: '1',
        classesLabel:this.chooseClassId,
        parentCommentId: this.replyInfo.id ? this.replyInfo.id : info.id
      };
      this.$api.commentSubmit(params).then(res => {
        if (res.data.code === 0) {
          this.$message({ message: '回复成功', type: 'success', duration: 1500, onClose: () => {
            // this.pageLoading = true
            // this.replyInfo = {}
            // this.getPushClassCommentList()
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '加载中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.getPushAllCount();
          }, });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 评论页码改变
    handleCurrentChange(val) {
      this.page.num = val;
      this.getPushClassCommentList();
    },
    // 评论每页显示条数改变
    handleSizeChange(size) {
      this.page.size = size;
      this.page.num = 1;
      this.getPushClassCommentList();
    },
    // 处理评论内容的张开收起
    handleCommentShowHide(info, index) {
      let commentInfo = JSON.parse(JSON.stringify(info));
      commentInfo.isShowHide = !commentInfo.isShowHide;
      this.$set(this.commentList, index, commentInfo);
    },
    // 处理回复的展开收起问题
    handleReplyShowHide(info, index) {
      let commentInfo = JSON.parse(JSON.stringify(info));
      if(!commentInfo.isReplyShowHide){
        if (commentInfo.children.length < 5) {
          commentInfo.childrenShowList = [];
          for (let i = 0; i < commentInfo.children.length; i++) {
            commentInfo.childrenShowList.push(JSON.parse(JSON.stringify(commentInfo.children[i])));
          }
          commentInfo.isReplyShowHide = false;
          commentInfo.replyPageNum = 1;
        } else {
          if ((commentInfo.replyPageNum + 1) * 5 >= info.children.length) {
            commentInfo.isReplyShowHide = true;
          } else {
            commentInfo.isReplyShowHide = false;
          }
          commentInfo.childrenShowList = [];
          let length = 0
          if((commentInfo.replyPageNum + 1) * 5 > info.children.length){
            length = info.children.length
          } else {
            length = (commentInfo.replyPageNum + 1) * 5
          }
          for (let i = 0; i < length; i++) {
            commentInfo.childrenShowList.push(JSON.parse(JSON.stringify(commentInfo.children[i])));
          }
          commentInfo.replyPageNum++;
        }
      } else {
        commentInfo.childrenShowList = [];
        for (let i = 0; i < 5; i++) {
          commentInfo.childrenShowList.push(JSON.parse(JSON.stringify(commentInfo.children[i])));
        }
        commentInfo.isReplyShowHide = false;
        commentInfo.replyPageNum = 1;
      }
      this.$set(this.commentList, index, commentInfo);
    },
    // 获取评论/回复是否点赞过信息
    getPublishClassCommentIsLink(id, callback) {
      this.$api.getPublishClassCommentIsLink({ commentoId: id }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data !== null) {
            callback && callback(true);
          } else {
            callback && callback(false);
          }
        } else {
          callback && callback(false);
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取评论的附件信息
    getCommentFileList(id, callback) {
      this.$api.getPublishClassCommentFileList({ commentoId: id }).then(res => {
        if (res.data.code === 0) {
          let resultList = res.data.data;
          let fileList = [];
          resultList.map((item) => {
            fileList.push({ id: item.id, fileUrl: item.fileUrl });
          });
          callback && callback(fileList);
        } else {
          callback && callback(false);
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取评论或者回复的点赞数和回复数
    getCommentCountList(id, callback) {
      this.$api.getPublishClassCommentCountInfo({ commentoId: id }).then(res => {
        if (res.data.code === 0) {
          let resultData = {
            likeCount: res.data.data.likeCount,
            replyCount: res.data.data.replyCount
          };
          callback && callback(resultData);
        } else {
          callback && callback(false);
          this.$message.error(res.data.msg);
        }
      });
    },
    // 处理班级变化
    handleChooseClass(id) {
      this.chooseClassId = id;
      this.$nextTick(() => {
        this.getPushAllCount();
        this.getPushClassCommentList();
      });
    },
    viewRecord() {
      this.courseViewDialogVisible = true;
    }, // 预览
    //预览富文本图片
    handleImgClick(e,fileList){
      console.log(e)
      let imgList = []
      fileList.map((item) => {
        if(item.fileType == 'img'){
          imgList.push(item.fileUrl)
        }
      })
      if(e.target.nodeName == 'IMG'){
        this.viewUrl = e.target.currentSrc
        this.viewUrlList = imgList
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true
        })
      }
    },
  },
  beforeDestroy() {
    document.getElementsByTagName('textarea').onkeyup = null;
  }
};
</script>
<style lang="scss" scoped>
.send-record-class-info{
  width: calc(100% - 48px);
  height: 194px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 2px;
  .class-info-outside{
    width: 100%;
    height: 43px;
    overflow-x: auto;
    .class-info-list{
      display: flex;
      align-items: center;
      .class-item{
        min-width: 90px;
        padding: 0 21px;
        height: 43px;
        line-height: 43px;
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
        cursor: pointer;
        &.active{
          border-radius: 60px;
          height: 39px;
          border: 2px solid #597EF7;
          font-size: 20px;
          font-weight: bold;
          color: #597EF7;
          line-height: 37px;
        }
      }
    }
  }
  .progress-info-outside{
    width: 100%;
    height: calc(100% - 61px);
    margin-top: 18px;
    overflow-x: auto;
    overflow-y: hidden;
    .progress-info{
      width: 1584px;
      .progress-item{
        width: 256px;
        height: 104px;
        padding: 13px 18px 13px 24px;
        border: 1px solid rgba(219, 219, 219, 0.7019607843137254);
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        /deep/.el-progress-circle{
          width: 105px !important;
          height: 105px !important;
        }
        /deep/.el-progress--circle, .el-progress--dashboard{
          vertical-align: middle;
        }
        /deep/.el-progress__text{
          font-family: 'DIN';
          font-size: 22px !important;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
        &:last-child{
          margin-right: 0;
        }
        .item-text{
          display: inline-block;
          vertical-align: middle;
          .el-divider--vertical{
            margin: 0 14px;
          }
          span{
            &:first-child{
              color: rgba(0, 0, 0, 0.65);
              font-size: 19px;
            }
            &:last-child{
              font-family: 'DIN';
              font-size: 22px;
            }
          }
        }
        .circle{
          width: 101px;
          height: 101px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 50%;
          position: relative;
          img{
            width: 37px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &.link{
            background-color: #6ADEBA;
          }
          &.reply{
            background-color: #5A4DC9;
          }
        }
      }
    }
  }
}
.send-record-list{
  width: calc(100% - 58px);
  padding: 24px 29px;
  background-color: #ffffff;
  margin-top: 18px;
  .send-recond-title{
    font-size: 22px;
    font-weight: 500;
    color: #000000;
  }
  .send-record-item{
    width: 100%;
    padding: 35px 0 11px 0;
    &:first-child{
      padding-top: 24px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    .record-item-header{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 29px;
      .header-left{
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
      .header-right{
        width: calc(100% - 84px);
        .item-title{
          width: 100%;
          font-size: 19px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 12px;
        }
        .item-content{
          width: 100%;
          font-size: 19px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.7;
        }
        .item-img{
          width: 100%;
          .file-item{
            width: 125px;
            height: 125px;
            display: inline-block;
            vertical-align: middle;
            margin: 15px 12px 0 0;
            img{
              width: 100%;
              height: 100%;
            }
            video{
              width: 100%;
              height: 100%;
            }
            audio{
              width: 100%;
            }
          }
        }
        .time-buttons{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 17px;
          .time{
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
    .reply-record{
      width: 100%;
      padding-bottom: 46px;
      border-radius: 9px 9px 0 0;
      background-color: #F8F8F8;
      .reply-record-item{
        width: calc(100% - 34px);
        padding: 28px 17px 7px 17px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .reply-img{
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .item-info{
          width: calc(100% - 72px);
          .item-title{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .name{
              span{
                &.text{
                  font-size: 19px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.65);
                  margin-right: 24px;
                }
                &.time{
                  font-size: 16px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.45);
                }
              }
            }
          }
          .item-content{
            width: 100%;
            font-size: 19px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 1.7;
          }
        }
      }
    }
    .reply-input{
      width: calc(100% - 56px);
      padding: 20px 28px;
      min-height: 53px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      border-radius: 0px 0px 9px 9px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .reply-input-outside{
        width: calc(100% - 125px);
        padding: 0 28px;
        background-color: #F9F8F8;
        border-radius: 25px;
        display: flex;
        align-items: center;
        .reply-person{
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          margin-right: 15px;
        }
      }
      .reply-input-textarea{
        width: 100%;
        /deep/.el-textarea__inner{
          background-color: #F9F8F8;
          border: none !important;
          border-radius: 0 !important;
          min-height: 53px !important;
          font-size: 19px;
        }
      }
      .send-reply-button{
        width: 53px;
        height: 53px;
        cursor: pointer;
      }
    }
  }
  .buttons{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    .button-item{
      display: flex;
      align-items: center;
      margin-right: 17px;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      img{
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  .show-or-hide{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    padding-top: 15px;
    cursor: pointer;
    img{
      margin-left: 10px;
    }
    .noShow{
      transform: rotate(180deg);
    }
  }
}
</style>
